"use client";

import React, { ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";
import { capitalizeFirstLetter } from "@/shared/helpers";

export interface Props {
  children: ReactNode;
  text: string | ReactNode;
  className?: string;
  tooltipClassName?: string;
  position?: ["left" | "center" | "right", "top" | "middle" | "bottom"];
  scaleDistance?: [number, number] | number;
}

const Tooltip = ({
  children,
  text,
  className,
  tooltipClassName,
  position = ["center", "middle"],
  scaleDistance = 1,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const scaleX = Array.isArray(scaleDistance)
    ? scaleDistance[0]
    : scaleDistance;
  const scaleY = Array.isArray(scaleDistance)
    ? scaleDistance[1]
    : scaleDistance;

  const positionX = {
    left: `-${100 * scaleX}%`,
    center: "-50%",
    right: `${100 * scaleX}%`,
  };
  const positionY = {
    top: `-${100 * scaleY}%`,
    middle: "-50%",
    bottom: `${100 * scaleY}%`,
  };

  const style = {
    ...(position[0] != "center"
      ? {
          [position[0]]: 0,
          [`margin${capitalizeFirstLetter(position[0])}`]: "-8px",
        }
      : { left: "50%" }),
    ...(position[1] != "middle" ? { [position[1]]: 0 } : { top: "50%" }),
    transform: `translate(${positionX[position[0]]}, ${
      positionY[position[1]]
    })`,
  };

  return (
    <div
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <div className="relative">
        {showTooltip && (
          <div
            className={twMerge(
              "absolute z-10 p-2 bg-gray-800 rounded-md w-fit max-w-[200px] text-white text-xs",
              tooltipClassName,
            )}
            style={style}
          >
            {text}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
